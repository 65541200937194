<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="期榜" prop="season_id">
        <el-select placeholder="选择期榜" v-model="searchForm.season_id">
          <el-option value="" label="全部"></el-option>
          <el-option
            v-for="(item, index) in seasonList"
            :value="item._id"
            :label="item.name + '-' + item.year + '年'"
            :key="`season_${index}`"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="门派ID" prop="sect_id">
        <el-input placeholder="门派ID" v-model="searchForm.sect_id"></el-input>
      </el-form-item>
      <el-form-item label="门派名称" prop="sect_name">
        <el-input
          placeholder="门派名称"
          v-model="searchForm.sect_name"
        ></el-input>
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-select v-model="searchForm.status" clearable placeholder="请选择">
          <el-option value="-1" label="全部"></el-option>
          <el-option value="0" label="编辑中"></el-option>
          <el-option value="1" label="线上"></el-option>
          <el-option value="2" label="回收站"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <perContainer :perm="`armory:${type}:publishrank`">
        <el-form-item>
          <el-button type="warning" @click="publish">发布</el-button>
        </el-form-item>
      </perContainer>
      <perContainer :perm="`armory:${type}:importrank`">
        <importButton :type="type" :sub_type="'rank'"></importButton>
      </perContainer>
      <perContainer :perm="`armory:${type}:importrank`">
        <importHistoryButton
          :type="type"
          :sub_type="'rank'"
        ></importHistoryButton>
      </perContainer>
      <!--      <el-form-item>-->
      <!--        <el-button @click="handleReset()">重置</el-button>-->
      <!--      </el-form-item>-->
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <el-table-column width="55">
        <template slot="header" slot-scope="scope">
          <el-checkbox
            @click.native.stop="handleSelectAll($event)"
            :value="selectAll"
            v-if="canPublishDatas.length > 0"
          ></el-checkbox>
        </template>
        <template slot-scope="scope">
          <el-checkbox
            @click.native.stop="handleSelectionChange(scope.row, $event)"
            v-model="scope.row.checked"
            v-if="scope.row.status != 2"
          ></el-checkbox>
        </template>
      </el-table-column>
      <template v-for="item in dataList">
        <el-table-column
          v-if="item.prop == 'subject.avatar'"
          :prop="item.prop"
          :key="item._id"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <img
              v-if="scope.row.subject.avatar && scope.row.subject.avatar.length"
              style="border-radius: 15px; width: 30px; height: 30px"
              :src="scope.row.subject.avatar"
            />
            <div v-else style="color: #777777; width: 30px; text-align: center">
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <perButton
            :perm="`armory:sect:rank`"
            @click.native.stop="handlePreview(scope.row)"
            >预览</perButton
          >
          <perButton
            :perm="`armory:sect:changerank`"
            @click.native.stop="handleEditPoints(scope.row)"
            >编辑积分</perButton
          >
          <popconfirmButton
            v-if="scope.row.status != 2"
            :perm="`armory:sect:changerank`"
            content="是否确认删除？"
            text="删除"
            @onConfirm="handleDelete(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
          <popconfirmButton
            v-else
            :perm="`armory:sect:changerank`"
            content="是否确认恢复？"
            text="恢复"
            @onConfirm="handleRecovery(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
    <integralEdit
      v-if="integralVisible"
      from="rank"
      :season_name="integralData.season_name"
      :sect_name="integralData.sect_name"
      :ref_id="integralData.ref_id"
      :season_id="integralData.season_id"
      @onClose="integralVisible = false"
    ></integralEdit>
    <preview
      v-if="previewData"
      :ref_id="previewData.ref_id"
      :season_id="previewData.season_id"
      :season_name="previewData.season_name"
      @closed="onPreviewClosed"
    ></preview>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import perButton from "@/components/perm/perButton";
import integralEdit from "./integralEdit";
import importButton from "../components/importButton";
import importHistoryButton from "../components/importHistoryButton";
import preview from "./preview";
export default {
  name: "sect_list",
  components: {
    perButton,
    integralEdit,
    importButton,
    importHistoryButton,
    preview,
  },
  props: {},
  data() {
    let self = this;
    return {
      ids: [],
      type: "sect",
      loading: false,
      previewData: false,
      pageSize: 10,
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        season_id: "",
        sect_id: "",
        sect_name: "",
        status: "",
      },
      seasonList: [],
      dataTable: [],
      dataList: [
        {
          prop: "season_name",
          label: "期榜",
          width: 300,
        },
        {
          prop: "subject.id",
          label: "门派ID",
          width: 150,
        },
        {
          prop: "subject.name",
          label: "门派名称",
          width: 300,
        },
        {
          prop: "subject.avatar",
          label: "门派头像",
          width: 100,
        },
        {
          prop: "subject.city",
          label: "城市",
          width: 150,
        },
        {
          prop: "value",
          label: "积分",
          width: 150,
        },
        {
          prop: "rank",
          label: "排名",
          width: 100,
          formatter: (row) => {
            if (row.rank && row.rank > 0 && row.status == 1) {
              return row.rank;
            }

            return "--";
          },
        },
        {
          prop: "status",
          label: "状态",
          width: 100,
          formatter: (row) => {
            switch (row.status) {
              case 0:
                return "编辑中";
              case 1:
                return "线上";
              case 2:
                return "回收站";
              default:
                return "";
            }
          },
        },
      ],
      parentTableData: [],
      integralVisible: false,
      integralData: {
        ref_id: "",
        sect_name: "",
        season_id: "",
        season_name: "",
      },
    };
  },
  computed: {
    canPublishDatas: function() {
      let tables = this.dataTable.filter((r) => r.status != 2);
      return tables;
    },
    selectAll: function() {
      let tables = this.canPublishDatas;
      let parentTableData = this.parentTableData;
      if (
        tables.length <= 0 ||
        tables.find((r) => parentTableData.indexOf(r._id) < 0)
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    onPreviewClosed() {
      this.previewData = false;
    },
    async publish() {
      if (!this.parentTableData.length) {
        this.$message.error("请选择要发布的数据");
        return;
      }

      this.loading = true;
      let { errorCode } = await this.$http.armory.publishRank(
        "sect",
        this.parentTableData
      );
      if (errorCode == "0000") {
        this.$message({
          type: "success",
          message: "发布成功",
        });
      }

      this.loading = false;
      this.handleQuery();
    },
    handleSelectAll($event) {
      if ($event.target.tagName !== "INPUT") return;
      if (this.selectAll) {
        this.parentTableData = [];
        this.dataTable.forEach((r) => {
          r.checked = false;
        });
      } else {
        this.parentTableData = [];
        this.dataTable
          .filter((r) => r.status != 2)
          .forEach((r) => {
            r.checked = true;
            this.parentTableData.push(r._id);
          });
      }
    },
    handleSelectionChange(row, $event) {
      if ($event.target.tagName !== "INPUT") return;
      let index = this.parentTableData.indexOf(row._id);
      if (index >= 0) {
        this.parentTableData.splice(index, 1);
      } else {
        this.parentTableData.push(row._id);
      }
    },
    async handlePreview(row) {
      this.previewData = {
        ref_id: row.ref_id,
        season_id: row.season_id,
        season_name: row.season_name,
      };
    },
    handleEditPoints(row) {
      this.integralData.ref_id = row.ref_id;
      this.integralData.season_id = row.season_id;
      this.integralData.sect_name = row.subject.name;
      this.integralData.season_name = row.season_name;
      this.integralVisible = true;
    },
    async handleDelete(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.armory.deleteRank(
        this.type,
        row._id
      );
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "删除成功",
      });

      row.status = data.status;
      if (row.status == 2) {
        row.checked = false;
        let index = this.parentTableData.indexOf(row._id);
        if (index >= 0) {
          this.parentTableData.splice(index, 1);
        }
      }

      row.rank = data.rank;
    },
    async handleRecovery(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.armory.recoveryRank(
        this.type,
        row._id
      );
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "恢复成功",
      });

      row.status = data.status;
      if (row.status == 2) {
        row.checked = false;
        let index = this.parentTableData.indexOf(row._id);
        if (index >= 0) {
          this.parentTableData.splice(index, 1);
        }
      }

      row.rank = data.rank;
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery();
    },
    async handleQuery() {
      const { pageNum } = this.pager;
      const { data, errorCode } = await this.$http.armory.rank(this.type, {
        ...this.searchForm,
        pageNum,
        pageSize: this.pageSize,
      });
      if (errorCode != "0000") return;
      this.parentTableData = [];
      data.data.forEach((r) => {
        r.checked = this.parentTableData.includes(r._id);
      });

      this.dataTable = data.data;
      this.pager = data.pager;
    },
    async onSeasonChanged(data) {
      await this.reloadSeasons();
      this.handleQuery();
    },
    onSectRankChanged(d) {
      let exist = this.dataTable.find((r) => r._id == d._id);
      console.log(d);
      if (exist) {
        exist._id = d._id;
        exist.season_id = d.season_id;
        exist.season_name = d.season_name;
        exist.value = d.value;
        exist.status = d.status;
        exist.ref_id = d.ref_id;
        exist.subject.id = d.sect_id;
        exist.subject.name = d.sect_name;
        exist.subject.avatar = d.sect_avatar;
        exist.subject.city = d.sect_city;
        exist.rank = d.rank;
        if (exist.status == 2) {
          exist.checked = false;
          let index = this.parentTableData.indexOf(exist._id);
          if (index >= 0) {
            this.parentTableData.splice(index, 1);
          }
        }
      } else {
        this.dataTable.push({
          ...d,
          subject: {
            id: d.sect_id,
            name: d.sect_name,
            avatar: d.sect_avatar,
            city: d.sect_city,
          },
        });
      }
    },
    async reloadSeasons() {
      const { data, errorCode } = await this.$http.armory.seasonList("sect", {
        valid: "1",
        pageNum: 0,
        pageSize: 1000,
      });
      if (errorCode != "0000") return;
      this.seasonList = data.data;
      if (!this.seasonList.find((r) => r._id == this.searchForm.season_id)) {
        this.searchForm.season_id = "";
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async created() {
    this.handleQuery();
    this.reloadSeasons();
    this.$bus.$off("onSeasonChanged", this.onSeasonChanged);
    this.$bus.$on("onSeasonChanged", this.onSeasonChanged);
    this.$bus.$off("onSectRankChanged", this.onSectRankChanged);
    this.$bus.$on("onSectRankChanged", this.onSectRankChanged);
  },
  destroyed() {
    this.$bus.$off("onSeasonChanged", this.onSeasonChanged);
    this.$bus.$off("onSectRankChanged", this.onSectRankChanged);
  },
};
</script>
<style lang="less">
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>
