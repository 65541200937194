<template>
  <el-tabs type="border-card">
    <el-tab-pane label="期榜管理" v-if="isPermSeason">
      <seasonManager type="sect"></seasonManager>
    </el-tab-pane>
    <el-tab-pane label="门派名单" v-if="isPermList">
      <sect_list></sect_list>
    </el-tab-pane>
    <el-tab-pane label="积分信息" v-if="isPermRank">
      <sect_rank></sect_rank>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import seasonManager from "../components/seasonManager/list"
import hasPerm from "@/utils/hasPermSign";
import sect_list from "./list"
import sect_rank from "./rank"
import {mapState} from "vuex";
export default {
  name: "sect_index",
  components: {
    seasonManager,
    sect_list,
    sect_rank
  },
  computed: {
    ...mapState(["permSign"])
  },
  data() {
    return {
      isPermSeason: true,
      isPermList: true,
      isPermRank: true,
    };
  },
  mounted() {
    this.$bus.$off("onPermSignChanged", this.onPermSignChanged);
    this.$bus.$on("onPermSignChanged", this.onPermSignChanged);
  },
  unmounted() {
    this.$bus.$off("onPermSignChanged", this.onPermSignChanged);
  },
  destroyed() {
    this.$bus.$off("onPermSignChanged", this.onPermSignChanged);
  },
  watch: {
    "$store.state.permSign": {
      handler: function (val) {
        this.onPermSignChanged();
      },
      immediate: true
    },
    "permSign": {
      handler: function (val) {
        this.onPermSignChanged();
      },
      immediate: true
    }
  },
  methods: {
    hasPerm,
    onPermSignChanged() {
      this.isPermSeason = this.hasPerm(`armory:sect:seasonlist`);
      this.isPermList = this.hasPerm(`armory:sect:list`);
      this.isPermRank = this.hasPerm(`armory:sect:rank`);
    }
  }
}
</script>

<style scoped>

</style>
